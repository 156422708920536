import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from './components/Navigation/Nav';
import Footer from './components/Footer/Footer';
import './App.css';
import Main from './components/Home/Main';

function App() {
  return (<>
    <Nav />
    <Main />
    <Footer />
  </>);
}
export default App;
