import React from 'react'
import { Link } from "react-router-dom";
export default function Banner() {
  return (
    <section className="cta-section section-padding section-bg">
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-5 col-12 ms-auto">
          <h2 className="mb-0">
            Make an impact. <br /> Save lives.
          </h2>
        </div>

        <div className="col-lg-5 col-12">
          {/* <Link to="/Donation" className="me-4">
            Make a donation
          </Link> */}

          <Link
            to='/Donation' href="#contribution_form"
            className="custom-btn btn smoothscroll"
          >
            Donate And Become a helping hand
          </Link>
        </div>
      </div>
    </div>
  </section>
  )
}
