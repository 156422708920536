import React from "react";
import { Link } from "react-router-dom";

export default function Landing() {
  return (
    <>
      <section className="hero-section hero-section-full-height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-12 p-0">
              <div
                id="hero-slide"
                className="carousel carousel-fade slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="images/bc-img/orphan-children-support.jpg"
                      className="carousel-image img-fluid"
                      alt="..."
                    />

                    <div className="carousel-caption d-flex flex-column justify-content-end">
                      <h1>
                        Be A <br /> Helping
                        <br /> Hand
                      </h1>

                      <span>
                        A charity that comes from the heart <br /> is always
                        better than a great charity <br />
                        that comes from the head.
                      </span>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <img
                      src="./images/bc-img/women-on-street.jpg"
                      className="carousel-image img-fluid"
                      alt="..."
                    />

                    <div className="carousel-caption d-flex flex-column justify-content-end">
                      <h1>Non-profit</h1>

                      <p>You can support us to grow more</p>
                    </div>
                  </div>

                  <div className="carousel-item">
                    <img
                      src="images/bc-img/orphan-children.jpg"
                      className="carousel-image img-fluid"
                      alt="..."
                    />

                    <div className="carousel-caption d-flex flex-column justify-content-end">
                      <h1>Humanity</h1>

                      <p>
                        Please tell your friends about our noble cause
                        organization
                      </p>
                    </div>
                  </div>
                </div>

                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#hero-slide"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>

                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#hero-slide"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-12 text-center mx-auto">
              <h2 className="mb-5">Welcome to Borkena Charity</h2>
            </div>

            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="featured-block d-flex justify-content-center align-items-center">
                <Link to="/Donation" className="d-block">
                  <img
                    src="images/icons/hands.png"
                    className="featured-block-image img-fluid"
                    alt=""
                  />

                  <p className="featured-block-text">
                    Become a <strong> contributor</strong>
                  </p>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
              <div className="featured-block d-flex justify-content-center align-items-center">
                <Link to="/Donation" className="d-block">
                  <img
                    src="images/icons/heart.png"
                    className="featured-block-image img-fluid"
                    alt=""
                  />

                  <p className="featured-block-text">
                    <strong>Caring</strong> For All
                  </p>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
              <div className="featured-block d-flex justify-content-center align-items-center">
                <Link to="/Donation" className="d-block">
                  <img
                    src="images/icons/receive.png"
                    className="featured-block-image img-fluid"
                    alt=""
                  />

                  <p className="featured-block-text">
                    Make a <strong>Donation</strong>
                  </p>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="featured-block d-flex justify-content-center align-items-center">
                <Link to="/Donation" className="d-block">
                  <img
                    src="images/icons/scholarship.png"
                    className="featured-block-image img-fluid"
                    alt=""
                  />

                  <p className="featured-block-text">
                    <strong>Orphan Need</strong> Education
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
