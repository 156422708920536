import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <div className="fixed-top " id="section_1">
      <nav className="navbar navbar-expand-lg bg-light shadow-lg">
        <div className="container">
          <Link className="navbar-brand" to="/" href="#top">
            <img
              src="images/bc-logo-transparent.png"
              className="logo img-fluid"
              alt="Borkena Charity"
            />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link click-scroll" to="/" href="#top">
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <a className="nav-link click-scroll" href="#about">
                  About
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link click-scroll" href="#causes">
                  Causes
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link click-scroll" href="#contributor">
                  Contributor
                </a>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link click-scroll dropdown-toggle"
                  href="#section_5"
                  id="navbarLightDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  News
                </a>

                <ul
                  className="dropdown-menu dropdown-menu-light"
                  aria-labelledby="navbarLightDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="{}">
                      News Listing
                    </a>
                  </li>

                  <li>
                    <a className="dropdown-item" href="{}">
                      News Detail
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <a className="nav-link click-scroll" href="#contact">
                  Contact
                </a>
              </li>

              <li className="nav-item ms-3">
                <Link
                  className="nav-link custom-btn custom-border-btn btn"
                  to="/Donation"
                >
                  <i class="bi bi-heart"></i> Donate Now
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
