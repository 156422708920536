import React from "react";
import Nav from "../Navigation/Nav";
import Footer from "../Footer/Footer";

export default function NotFound() {
  return (
    <>
      <Nav />
      <div className="text-center mt-5 pt-5 ">
        <div className="pt-5 pb-5 mb-5">
          <h1 className="pb-5">Page Not Found</h1>
          <img
            className="img-fluid p-5"
            src="./images/404-not-found.png"
            alt="404 Page Not Found"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
