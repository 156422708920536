import React from "react";

export default function Testimonial() {
  return (
    <section className="testimonial-section section-padding section-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12 mx-auto">
            <h2 className="mb-lg-3">Our Contributor's Voice</h2>

            <div
              id="testimonial-carousel"
              className="carousel carousel-fade slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="carousel-caption">
                    <h4 className="carousel-title">
                      We need a kind of organization like you. We appreciate
                      your hard work
                    </h4>

                    <small className="carousel-name">
                      <span className="carousel-name-title">Kedir</span>, Hussen
                    </small>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="carousel-caption">
                    <h4 className="carousel-title">
                      We are always with you. Keep up the good work!
                    </h4>

                    <small className="carousel-name">
                      <span className="carousel-name-title">Abdulmalik</span>,
                      Jemal
                    </small>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="carousel-caption">
                    <h4 className="carousel-title">
                      I am always with you to help achieve your mission.
                    </h4>

                    <small className="carousel-name">
                      <span className="carousel-name-title">Halima</span>,
                      Hussen
                    </small>
                  </div>
                </div>

                <div className="carousel-item">
                  <div className="carousel-caption">
                    <h4 className="carousel-title">
                      There are no words to explain what you are doing for those
                      who are in need. I am always supportive of your mission.
                    </h4>

                    <small className="carousel-name">
                      <span className="carousel-name-title">Ali</span>, Kemal
                    </small>
                  </div>
                </div>

                <ol className="carousel-indicators">
                  <li
                    data-bs-target="#testimonial-carousel"
                    data-bs-slide-to="0"
                    className="active"
                  >
                    <img
                      src="images/avatar/male-avatar-one.png"
                      className="img-fluid rounded-circle avatar-image"
                      alt="avatar"
                    />
                  </li>

                  <li
                    data-bs-target="#testimonial-carousel"
                    data-bs-slide-to="1"
                    className=""
                  >
                    <img
                      src="images/avatar/male-avatar-two.png"
                      className="img-fluid rounded-circle avatar-image"
                      alt="avatar"
                    />
                  </li>

                  <li
                    data-bs-target="#testimonial-carousel"
                    data-bs-slide-to="2"
                    className=""
                  >
                    <img
                      src="images/avatar/female-avatar.png"
                      className="img-fluid rounded-circle avatar-image"
                      alt="avatar"
                    />
                  </li>

                  <li
                    data-bs-target="#testimonial-carousel"
                    data-bs-slide-to="3"
                    className=""
                  >
                    <img
                      src="images/avatar/male-avatar-three.png"
                      className="img-fluid rounded-circle avatar-image"
                      alt="avatar"
                    />
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
