import React from "react";
import { Link } from "react-router-dom";

export default function Causes() {
  return (
    <section className="section-padding" id="causes">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-12 text-center mb-4">
            <h2>Our Causes</h2>
          </div>

          <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
            <div className="custom-block-wrap">
              <img
                src="images/bc-img/orphan-children.jpg"
                className="custom-block-image img-fluid"
                alt=""
              />

              <div className="custom-block">
                <div className="custom-block-body">
                  <h5 className="mb-3">Orphan Children Support</h5>

                  <p>
                    Our main cause for orphan children is getting basic needs in
                    life, and provide a safe place to grow.
                  </p>

                  <div className="progress mt-4">
                    <div
                      className="progress-bar w-75"
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>

                  <div className="d-flex align-items-center my-2">
                    <p className="mb-0">
                      <strong>Raised:</strong>
                      $5,700
                    </p>

                    <p className="ms-auto mb-0">
                      <strong>Goal:</strong>
                      $30,000
                    </p>
                  </div>
                </div>

                <Link to="/Donation" className="custom-btn btn">
                  Donate now
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
            <div className="custom-block-wrap">
              <img
                src="images/bc-img/women-on-street.jpg"
                className="custom-block-image img-fluid"
                alt=""
              />

              <div className="custom-block">
                <div className="custom-block-body">
                  <h5 className="mb-3">Poverty Development</h5>

                  <p>
                    We support women in disability, aged, and have no children
                    or husband. It is our responsibility{" "}
                  </p>

                  <div className="progress mt-4">
                    <div
                      className="progress-bar w-50"
                      role="progressbar"
                      aria-valuenow="50"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>

                  <div className="d-flex align-items-center my-2">
                    <p className="mb-0">
                      <strong>Raised:</strong>
                      $2,600
                    </p>

                    <p className="ms-auto mb-0">
                      <strong>Goal:</strong>
                      $60,000
                    </p>
                  </div>
                </div>

                <Link to="/Donation" className="custom-btn btn">
                  Donate now
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-12">
            <div className="custom-block-wrap">
              <img
                src="images/bc-img/old-man-dessie.jpg"
                className="custom-block-image img-fluid"
                alt=""
              />

              <div className="custom-block">
                <div className="custom-block-body">
                  <h5 className="mb-3">
                    Financially vulnerable Elders, and women help
                  </h5>

                  <p>
                    It is our responsibility to support elder who didn't have
                    anyone to support them. Elders are the assets of our society
                    who gave their lives to grow us with little income.
                  </p>

                  <div className="progress mt-4">
                    <div
                      className="progress-bar w-100"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>

                  <div className="d-flex align-items-center my-2">
                    <p className="mb-0">
                      <strong>Raised:</strong>
                      $4,600
                    </p>

                    <p className="ms-auto mb-0">
                      <strong>Goal:</strong>
                      $100,000
                    </p>
                  </div>
                </div>

                <Link to="/Donation" className="custom-btn btn">
                  Donate now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
