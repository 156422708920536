import React from "react";

export default function Volunteer() {
  return (
    <section className="volunteer-section section-padding" id="contributor">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12">
            <h2 className="text-white mb-4">Volunteer Today</h2>

            <form
              className="custom-form volunteer-form mb-5 mb-lg-0"
              action="#"
              method="post"
              role="form"
            >
              <h3 className="mb-4">Become a Borkena Charity Volunteer Today</h3>

              <div className="row">
                <div className="col-lg-6 col-12">
                  <input
                    type="text"
                    name="volunteer-name"
                    id="volunteer-name"
                    className="form-control"
                    placeholder="Full Name"
                    required
                  />
                </div>

                <div className="col-lg-6 col-12">
                  <input
                    type="email"
                    name="volunteer-email"
                    id="volunteer-email"
                    pattern="[^ @]*@[^ @]*"
                    className="form-control"
                    placeholder="youremail@domain.com"
                    required
                  />
                </div>

                <div className="col-lg-12 col-12">
                  <input
                    type="text"
                    name="volunteer-subject"
                    id="volunteer-subject"
                    className="form-control"
                    placeholder="Subject"
                    required
                  />
                </div>
              </div>
              <textarea
                name="volunteer-message"
                rows="5"
                className="form-control"
                id="volunteer-message"
                placeholder="How you would like to Contribute detail"
              ></textarea>

              <button type="submit" className="form-control">
                Submit
              </button>
            </form>
          </div>

          <div className="col-lg-6 col-12">
            <img
              src="images/bc-img/img-eight.png"
              className="volunteer-image img-fluid"
              alt=""
            />

            <div className="custom-block-body text-center">
              <h4 className="text-white mt-lg-3 mb-lg-3">About Contributing</h4>

              <p className="text-white">
                You may contribute on a monthly, quarterly, or yearly bases. If
                you would like to contribute with other payment methods than
                credit card or Paypal. You may send us your check or money order
                in our box 000 in Seattle, WA USA. If you have another means of
                contribution, please fill out the form and send us. We will
                contact you at our earliest possible time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
