import React from "react";
import Home from "./Home";

export default function Main() {
  return (
    <>
      <Home />
    </>
  );
}
