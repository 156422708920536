import React from "react";
import Landing from "./Landing";
import About from "./About";
import Causes from "./Causes";
import News from "./News";
import Banner from "./Banner";
import Contact from "./Contact";
import Testimonial from "./Testimonial";
import Volunteer from "./Volunteer";

export default function Home() {
  return (
    <>
      <Landing />
      <About />
      <Causes />
      <Volunteer />
      <News />
      <Banner />
      <Contact />
      <Testimonial />
    </>
  );
}
