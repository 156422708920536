import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());
  const scrollTop = () => window.scrollTo(0, 0);
  useEffect(() => {
    getYear();
    scrollTop();
  }, []);

  let scrollUp = document.getElementById("btn-back-to-top");

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      scrollUp.style.display = "block";
    } else {
      scrollUp.style.display = "none";
    }
  }
  function backToTop() {
    scrollTop();
  }
  return (
    <div>
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-12 mb-4">
              <img
                src="images/bc-logo-transparent.png"
                className="logo img-fluid"
                alt=""
              />
            </div>

            <div className="col-lg-4 col-md-6 col-12 mb-4">
              <h5 className="site-footer-title mb-3">Quick Links</h5>

              <ul className="footer-menu">
                <li className="footer-menu-item">
                  <a href="{}" className="footer-menu-link">
                    Our Story
                  </a>
                </li>

                <li className="footer-menu-item">
                  <a href="{}" className="footer-menu-link">
                    Newsroom
                  </a>
                </li>

                <li className="footer-menu-item">
                  <a href="{}" className="footer-menu-link">
                    Causes
                  </a>
                </li>

                <li className="footer-menu-item">
                  <a href="{}" className="footer-menu-link">
                    Become A Contributor
                  </a>
                </li>

                <li className="footer-menu-item">
                  <a href="{}" className="footer-menu-link">
                    Partner with us
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4 col-md-6 col-12 mx-auto">
              <h5 className="site-footer-title mb-3">Contact Information</h5>

              <p className="text-dark d-flex mb-2">
                <i className="bi-telephone me-2"></i>

                <a href="tel: 305-240-9671" className=" site-footer-link">
                  206-415-5022
                </a>
              </p>

              <p className="d-flex">
                <i className="bi-envelope me-2"></i>

                <a
                  href="mailto:borkena.charity@gmail.com"
                  className="text-dark site-footer-link"
                >
                  info@borkenacharity.org
                </a>
              </p>

              <p className="text-dark d-flex mt-3">
                <i className="bi-geo-alt me-2"></i>
                SeaTac, WA USA
              </p>

              <a href="{}" className="custom-btn btn mt-3">
                Get Direction
              </a>
            </div>
          </div>
        </div>

        <div className="site-footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7 col-12">
                <p className="copyright-text mb-0">
                  Copyright © {date}
                  <span id="year"></span>
                  <a href="{}">
                    <strong className="text-white"> Borkena </strong>
                  </a>
                  Charity Organization
                  <Link to="/Privacy">
                    <strong className="text-white"> Privacy</strong>
                  </Link>
                </p>
              </div>

              <div className="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                <ul className="social-icon">
                  <li className="social-icon-item">
                    <Link
                      href="{}"
                      className="social-icon-link bi-twitter"
                    ></Link>
                  </li>

                  <li className="social-icon-item">
                    <Link
                      href="{}"
                      className="social-icon-link bi-facebook"
                    ></Link>
                  </li>

                  <li className="social-icon-item">
                    <Link
                      href="{}"
                      className="social-icon-link bi-instagram"
                    ></Link>
                  </li>
                  <li className="social-icon-item">
                    <a
                      href="https://youtube.com/borkenacharity"
                      className="social-icon-link bi-youtube"
                    >
                      .
                    </a>
                  </li>
                </ul>
                <button
                  type="button"
                  class="btn btn-secondary btn-floating"
                  id="btn-back-to-top"
                  onClick={backToTop}
                >
                  <i class="bi bi-arrow-up-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
