import React from "react";
import Footer from "../Footer/Footer";
import Nav from "../Navigation/Nav";

export default function Donation() {
  return (
    <>
      <Nav />
      <section className="donate-section">
        <div className="section-overlay"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-12 mx-auto">
              <form
                className="custom-form donate-form"
                action="#"
                method="get"
                role="form"
              >
                <h3 className="mb-4">Make a donation</h3>

                <div className="row">
                  <div className="col-lg-12 col-12">
                    <h5 className="mb-3">Donation Frequency</h5>
                  </div>
                  <hr />
                  <div className="col-lg-6 col-6 form-check-group form-check-group-donation-frequency">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input frequency"
                        type="radio"
                        name="DonationFrequency"
                        id="DonationFrequencyOne"
                        checked
                      />

                      <label
                        className="form-check-label frequency"
                        for="DonationFrequencyOne"
                      >
                        One Time
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 col-6 form-check-group form-check-group-donation-frequency">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input frequency"
                        type="radio"
                        name="DonationFrequency"
                        id="DonationFrequencyMonthly"
                      />

                      <label
                        className="form-check-label frequency"
                        for="DonationFrequencyMonthly"
                      >
                        Monthly
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12">
                    <h5 className="mt-2 mb-3">Select an amount</h5>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input frequency"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefaultTen"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefaultTen"
                      >
                        {/* <!-- <strong id="amount">$</strong> --> */}
                        $10
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault15"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault15"
                      >
                        $15
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault20"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault20"
                      >
                        $20
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault25"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault25"
                      >
                        $25
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault30"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault30"
                      >
                        $30
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault50"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault50"
                      >
                        $50
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault100"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault100"
                      >
                        $100
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault200"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault200"
                      >
                        $200
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault300"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault300"
                      >
                        $300
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-6 form-check-group">
                    <div className="form-check form-check-radio">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault500"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault500"
                      >
                        $500
                      </label>
                    </div>
                  </div>

                  <div className="col-lg-6 col-12 form-check-group">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        $
                      </span>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Custom amount"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-12">
                    <h5 className="mt-1">Personal Info | Full Name | Email</h5>
                  </div>

                  <div className="col-lg-6 col-12 mt-2">
                    <input
                      type="text"
                      name="donation-name"
                      id="donation-name"
                      className="form-control"
                      placeholder="Full Name"
                      required
                    />
                  </div>

                  <div className="col-lg-6 col-12 mt-2">
                    <input
                      type="email"
                      name="donation-email"
                      id="donation-email"
                      pattern="[^ @]*@[^ @]*"
                      className="form-control"
                      placeholder="youremail@domain.com"
                      required
                    />
                  </div>

                  <div className="col-lg-12 col-12">
                    <h5 className="mt-4 pt-1">Choose Payment</h5>
                  </div>

                  <div className="col-lg-12 col-12 mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="DonationPayment"
                        id="flexRadioDefault9"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault9"
                      >
                        <i className="bi-credit-card custom-icon ms-1"></i>
                        Debit or Credit card
                      </label>
                    </div>

                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="DonationPayment"
                        id="flexRadioDefault10"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault10"
                      >
                        <i className="bi-paypal custom-icon ms-1"></i>
                        Paypal
                      </label>
                    </div>

                    <button type="submit" className="form-control mt-4">
                      Submit Donation
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
