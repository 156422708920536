import React from "react";

export default function About() {
  return (
    <section className="section-padding section-bg" id="about">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-12 mb-5 mb-lg-0">
            <img
              src="images/bc-img/about-us.svg"
              className="custom-text-box-image img-fluid"
              alt=""
            />
          </div>

          <div className="col-lg-6 col-12">
            <div className="custom-text-box">
              <h2 className="mb-2">Our Story</h2>

              <h5 className="mb-3">
                Borkena Charity, a Non-Profit Organization
              </h5>

              <p className="mb-0">
                A group of individuals who come together to help those who are
                in need of help. Seeing a person in need of help for a basic
                necessity breaks a healthy heart. When we see that society who
                are unable to provide basic needs, that makes us come together
                and organize this charitable organization to do our part and
                facilitate the means to be able to reach them on time.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="custom-text-box mb-lg-0">
                  <h5 className="mb-3">Our Mission</h5>

                  <p>
                    Borkena Charity is a nonprofit charitable organization
                    established in 2022. Its mission is to facilitate basic
                    needs for women, orphan children, elders, and disabled
                    societies in developing countries as a charitable
                    organization in East Africa Ethiopia. It also works to
                    improve their vulnerable life.
                  </p>

                  <ul className="custom-list mt-2">
                    <li className="custom-list-item d-flex">
                      <i className="bi-check custom-text-box-icon me-2"></i>
                      Support Elders, orphans, and women
                    </li>

                    <li className="custom-list-item d-flex">
                      <i className="bi-check custom-text-box-icon me-2"></i>
                      Helping Orphan's vulnerable life
                    </li>
                  </ul>
                </div>
                <br />
                <div className="col-lg-12 col-md-12 col-12">
                  <div className="custom-text-box mb-lg-0">
                    <h5 className="mb-3">Our Vision </h5>

                    <p>
                      Borkena Charity vision is to create an environment where
                      orphan children, elders, and disabled societies achieve
                      their goals in life.
                    </p>

                    <ul className="custom-list mt-2">
                      <li className="custom-list-item d-flex">
                        <i className="bi-check custom-text-box-icon me-2"></i>
                        Helping Orphans
                      </li>

                      <li className="custom-list-item d-flex">
                        <i className="bi-check custom-text-box-icon me-2"></i>
                        Support Basic Needs
                      </li>
                    </ul>
                  </div>
                </div>
                <br />
                <div className="col-lg-12 col-md-12 col-12"></div>
                <br />
                <div className="custom-text-box d-flex flex-wrap d-lg-block mb-lg-0">
                  <div className="counter-thumb">
                    <div className="d-flex">
                      <span
                        className="counter-number"
                        data-from="1"
                        data-to="2022"
                        data-speed="1000"
                      ></span>
                      <span className="counter-number-text"></span>
                    </div>

                    <span className="counter-text">Founded In</span>
                  </div>

                  <div className="counter-thumb mt-4">
                    <div className="d-flex">
                      <span
                        className="counter-number"
                        data-from="1"
                        data-to="12"
                        data-speed="1000"
                      ></span>
                      <span className="counter-number-text">K</span>
                    </div>

                    <span className="counter-text">Donations</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
